/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '@taiga-ui/core/styles/taiga-ui-theme.less';
@import '@taiga-ui/core/styles/taiga-ui-fonts.less';
@import '@taiga-ui/styles/taiga-ui-global.less';
@import 'override-taiga';
@import 'common-style';
@import 'tab-content-style';
@import 'mixins';
@import 'common/assets/styles/colors';
@import 'common/assets/styles/tui-inputs.less';
@import 'common/assets/styles/tui-btn.less';
@import 'common/assets/styles/tui-hint.less';
@import 'common/assets/styles/tui-tabs.less';
@import 'common/assets/styles/tui-svg.less';
@import 'common/assets/styles/tui-toggle.less';

.leaflet-control-attribution {
  display: none;
}

* {
  font-family: 'Inter', sans-serif;
}

:root {
  --tui-font-text-m: var(--tui-font-text-s);
  //--tui-base-03: #e3e3e3;
  --tui-primary: @primary-color;
  --tui-link: @primary-color;
  --tui-primary-hover: @primary-color-hover;
  --tui-primary-active: @primary-color-hover;
  --tui-secondary-hover: @light-green;
  --tui-secondary-active: @light-green-pressed;
  --tui-link-hover: @primary-color;
  --tui-warning-bg: #fff2d1;

  --tui-radius-m: 6px;

  --readonly-access: @light-blue;
  --full-access: @light-green;
  --custom-access: @light-yellow;

  font-family: 'Inter', sans-serif;

  ::selection {
    background-color: @primary-color;
    color: white;
  }
}

body {
  background: var(--tui-base-01);

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: @common-padding;
  }

  hr {
    background: var(--tui-base-02);
  }
}

tui-svg {
  color: @text-color;
}

tui-data-list {
  button {
    justify-content: flex-start !important;
  }
}

tui-tabs {
  padding-left: 20px;
}

tui-notification {
  a {
    text-decoration: none;
    color: @primary-color;
  }
}

table {
  .actions {
    width: 48px;
  }

  tr {
    th,
    td {
      border: 1px solid var(--tui-base-02) !important;

      &.not-border {
        border: none !important;
      }
    }
  }

  //background: var(--tui-base-01);

  .hover:hover,
  .hover-with-context {
    td {
      background-color: var(--tui-base-02);
    }
  }
}

.leaflet-marker-pane > * {
  -webkit-transition: transform 0.2s linear;
  -moz-transition: transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  -ms-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
}

.default {
  cursor: default !important;
}

.portal-page {
  .header {
    padding: 10px 20px;
    //border-bottom: 1px solid @background-color-active;
    border-bottom: 1px solid var(--tui-base-02);
    gap: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    tui-input {
      width: 100%;
    }

    tui-select {
      width: 150px;
    }
  }

  .content,
  .content_subgroup {
    .list-item {
      display: flex;
      padding: 6px 20px;
      align-items: center;
      //border-bottom: 1px solid @background-color-active;
      border-bottom: 1px solid var(--tui-base-02);
      justify-content: space-between;
      gap: 12px;

      &_one-line {
        padding: 6px 20px;
      }

      .item-text-68 {
        width: calc(100% - 120px);
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .display-block {
        display: block;
      }

      .truncated {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        font-size: 15px;
        font-weight: 500;
      }

      .address {
        color: @text-color;
      }

      .length-group {
        color: @text-2;
        min-width: 105px;
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        //color: @text-color;
      }
    }

    &_subgroup {
      .list-item {
        padding: 6px 20px 6px 54px;
      }
    }
  }
}

.p-0 {
  padding: 0;
}

ngx-dadata {
  .autocomplete {
    border: 2px solid var(--tui-base-03);
    border-radius: 6px;
    margin: 0;
    padding: 0;
    height: 36px;

    &:has(> input:focus-visible) {
      border-color: @primary-color;
    }

    input[type='text'] {
      background: transparent;
      border: none;
      line-height: 36px;
      padding: 0;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;

      &:focus-visible {
        border: none;
        padding: 0;
        padding-left: 8px;
        padding-right: 8px;
        outline-width: 0;
      }
    }
  }
}

.object-card-icon-select__item {
  width: 24px;
  height: 24px;
  display: flex;
  //align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

tui-checkbox {
  tui-svg {
    color: white;
  }
}
