@import 'colors';

[tuiWrapper][data-appearance='textfield'][data-appearance='textfield']:after {
  border-width: 2px;
}

[tuiWrapper][data-appearance='textfield'][data-mode='onDark']:after {
  color: var(--tui-base-03);
  opacity: 1;
}

tui-input,
tui-select,
tui-input-number,
tui-input-password,
tui-input-count,
tui-primitive-textfield,
tui-hosted-dropdown,
tui-textarea {
  height: 40px;

  tui-primitive-textfield > div {
    height: 40px !important;
    box-shadow: none !important;
    //border-radius: 6px !important;
  }

  & > div {
    //height: 40px !important;
    box-shadow: none !important;
    //border-radius: 6px !important;
  }
}

[tuiWrapper][data-appearance='input-file']:hover:not(._no-hover),
[tuiWrapper][data-appearance='input-file'][data-state='hover'] {
  background-color: var(--tui-base-02) !important;
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: none;
}

tui-checkbox-labeled,
tui-primitive-checkbox,
tui-checkbox {
  //color: @text-2;
  color: var(--tui-text-02);

  label.t-wrapper {
    align-items: center;
  }

  &:has(.t-mark_on) {
    .t-content {
      //color: @color-active;
      color: var(--tui-text-01);
    }
  }

  .t-svg {
    color: @background-color-hover;
  }
}
