@import 'colors';


tui-tabs {
  color: var(--tui-primary);
  box-shadow: inset 0 -1px var(--tui-base-02) !important;

  tui-underline {
    color: var(--tui-primary) !important;
  }

  &.tab-icon {
    width: 68px;
    height: 36px;
    border-radius: 6px;
    border: 2px solid var(--tui-base-03);
    padding-left: 10px;

    button {
      --tui-tab-margin: 10px !important;
    }
  }

  button {
    &._active {
      color: var(--tui-primary) !important;

      tui-svg {
        color: var(--tui-primary) !important;
      }
    }

    &:hover {
      color: var(--tui-primary-hover) !important;
    }

    .t-highlight {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: inherit;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
