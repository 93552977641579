.list {

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 0 0;
    cursor: pointer;

    .fields {
      width: 100%;
      display: flex;

      &_select, &_input {
        width: 50%;
      }

      &_select {
        margin-right: 5px;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
    }

    tui-svg {
      color: var(--tui-base-06);
    }
  }

  &__item {
    margin-right: 10px;
    color: var(--tui-base-08);
    border-bottom: 1px solid var(--tui-base-04);
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border: 0;
    }

    .icon-name {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;

      .icon {
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;

        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid var(--tui-base-04);
}
