body{
    // --tui-radius-m: 0px;
}
tui-hint{
    max-width: 100% !important;
}

tui-dialog {
  border-radius: 6px !important;

  //& > .t-content {
  //  padding: 24px;
  //}
//    width: 57rem !important;
}
